import { includes, path } from 'ramda';
import { NATIVE_ACTIONS } from '../../lib/models/native';
import api from '../../lib/api';
import actions from '../actions'; // eslint-disable-line
import { emit } from './events'; // eslint-disable-line
import { getToken } from '../selectors/auth';
import { localize } from './location';
import { ADD_USER_ENTITLEMENTS, REFRESH_USER } from './auth'; // eslint-disable-line
import { setSnackBar } from './ui'; // eslint-disable-line

const PREMIUM_PURCHASED_NOTIFICATION_EVENT = 'PREMIUM_PURCHASED_NOTIFICATION_EVENT';
const CLOSE_PREMIUM_PURCHASED_NOTIFICATION_EVENT = 'CLOSE_PREMIUM_PURCHASED_NOTIFICATION_EVENT';

const reducer = (state = { isNative: true }, action = {}) => {
  switch (action.type) {
    case NATIVE_ACTIONS.IS_NATIVE:
      return { ...state, isNative: true };
    case PREMIUM_PURCHASED_NOTIFICATION_EVENT:
      return { ...state, showPremiumToast: true };
    case CLOSE_PREMIUM_PURCHASED_NOTIFICATION_EVENT:
      return { ...state, showPremiumToast: false };

    default:
      return state;
  }
};

export const handleNativeActions = ({ type, ...data }) => (dispatch, getState) => {
  if (type && includes('@events', type)) return dispatch(emit({ type, ...data }));
  switch (type) {
    case NATIVE_ACTIONS.VALIDATE_USER:
      return dispatch(actions.auth.signedIn(data.token, data));
    case NATIVE_ACTIONS.SGININ_WITH_GOOGLE:
      return dispatch(actions.auth.signInWithGoogle({ ...data, origin: 'native', ...data.user }));
    case NATIVE_ACTIONS.SGININ_WITH_EMAIL:
      return api.auth.addExpoToken(data);
    case NATIVE_ACTIONS.SGININ_WITH_FACEBOOK:
      return dispatch(actions.auth.signIn({ ...data, origin: 'native' }));
    case NATIVE_ACTIONS.IS_NATIVE: {
      const token = getToken(getState());
      if (token) dispatch(actions.events.loadAll());
      return dispatch({ type });
    }
    case NATIVE_ACTIONS.TRACK_MY_LOCATION:
      return dispatch(localize(data.coords));
    case NATIVE_ACTIONS.GET_PREMIUM_INFO: {
      dispatch({ type: ADD_USER_ENTITLEMENTS, entitlements: path(['data', 'entitlements'], data) });
      return dispatch(data);
    }
    case NATIVE_ACTIONS.PREMIUM_PURCHASED_NOTIFICATION: {
      return dispatch({ type: PREMIUM_PURCHASED_NOTIFICATION_EVENT });
    }
    default:
      return null;
  }
};

export const closePremiumToast = () => dispatch => {
  dispatch({ type: CLOSE_PREMIUM_PURCHASED_NOTIFICATION_EVENT });
};

export const postMessage = data => () => {
  // const isNative = getIsNative(getState());
  if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify(data));
};

const nativeActions = { handleNativeActions, postMessage, closePremiumToast };
export default { reducer, actions: nativeActions };
